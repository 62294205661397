<template>
  <QuizFieldWrapper :title="fieldTitle" :description="fieldDescription">
    <div class="quiz__day">
          <div class="quiz__day-items">
            <div
              class="quiz__day-item-wrapper"
              v-for="t in question.answers"
              :key="t.value"
            >
              <input
                type="radio"
                name="typical_day"
                :id="t.value"
                class="quiz__checkbox"
                :value="t.value"
                v-model="proxyValue"
              />
              <label class="quiz__day-item" :for="t.value">
                <img :src="t.img" alt="image" class="quiz__day-item-img" />
                <div class="quiz__day-item-title">{{ t.text }}</div>
              </label>
            </div>
          </div>
        </div>
  </QuizFieldWrapper>
</template>


<script>
import QuizFieldWrapper from './QuizFieldWrapper.vue'

export default {
  name: 'QuizTypicalDay',
  components: { QuizFieldWrapper },
  props: ['fieldTitle', 'fieldDescription', 'question', 'value'],
  emits: ['next', 'fieldChange'],
  computed: {
    proxyValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('fieldChange', newValue)
      }
    }
  },
}

</script>