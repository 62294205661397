<template>
  <QuizFieldWrapper :title="fieldTitle" :description="fieldDescription">
    <template #default>
      <div class="quiz__fields">
        <template v-for="(ans, ind) in questionsValue" :key="ind">
          <div class="quiz__field-wrapper">
            <input
              :type="isMulti ? 'checkbox' : 'radio'"
              v-model="proxyValue"
              :name="ans.text"
              :value="ans.value"
              :id="ans.text"
              class="quiz__checkbox"
            />
            <label class="quiz__label-wrapper" :for="ans.text">
              <div class="quiz__field-text">{{ ans.text }}</div>
            </label>
          </div>
        </template>
      </div>
    </template>
    <template #actions>
      <div class="quiz-btn__container" v-if="isMulti">
        <button
          :disabled="proxyValue.length === 0"
          class="quiz__btn btn-reset quiz-btn quiz-btn--md quiz-btn--white"
          @click="$emit('next')"
        >
          Continue
        </button>
      </div>
    </template>
  </QuizFieldWrapper>
</template>

<script>
import QuizFieldWrapper from './QuizFieldWrapper.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'QuizMultiSingleField',
  components: { QuizFieldWrapper },
  // props: ['fieldTitle', 'fieldDescription', 'question', 'value', 'isMulti'],
  props: {
    fieldTitle: {
      type: String,
    },
    fieldDescription: {
      type: String,
    },
    question: {
      type: Array,
    },
    value: {
      type: [String, Array],
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['next', 'fieldChange'],
  computed: {
    proxyValue: {
      get() {
        return this.value || []
      },
      set(newValue) {
        this.$emit('fieldChange', newValue)
        if (!this.isMulti) {
          this.$emit('next')
        }
      },
    },
    questionsValue: {
      get() {
        return this.question.map((item) => {
          if (typeof item === 'string') {
            return { value: item, text: item }
          } else return item
        })
      },
    },
  },
}
</script>
