<template>
  <div class="progress-bar">
    <div class="progress-bar__dot-start"></div>
    <div class="progress-bar__dot"></div>
    <svg>
      <defs>
        <linearGradient id="linear" x1="70%" y1="90%" x2="40%" y2="30%">
          <stop offset="0%" stop-opacity="0.4" stop-color="#fff" />
          <stop offset="100%" stop-opacity="1.0" stop-color="#fff" />
        </linearGradient>
      </defs>
      <circle cx="70" cy="70" r="70"></circle>
      <circle cx="70" cy="70" r="70"></circle>
    </svg>
    <div class="progress-bar__number">
      <span>{{ percentResult }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    isContinue: {
      type: Boolean,
      default: false,
    },
    full: Boolean,
  },
  data() {
    return {
      percent: 0,
      interval: null,
    }
  },
  computed: {
    percentResult() {
      if (this.isContinue || this.full) {
        return this.percent <= 100 ? this.percent.toFixed(0) : 100
      }
      return this.percent <= 92 ? this.percent : 92
    },
  },
  watch: {
    isContinue: {
      immediate: true,
      handler() {
        this.percent = this.isContinue ? 92 : 0
      },
    },
  },
  mounted() {
    document.documentElement.style.setProperty(
      '--dash-offset',
      this.isContinue ? 20 : 440
    )
    document.documentElement.style.setProperty(
      '--rotateEnd',
      this.isContinue || this.full ? '360deg' : '340deg'
    )
    document.documentElement.style.setProperty(
      '--dash-offset-end',
      this.isContinue || this.full ? 0 : 30
    )
    document.documentElement.style.setProperty(
      '--rotate',
      this.isContinue ? '350deg' : '0deg'
    )
    this.interval = setInterval(
      () => {
        const end = this.isContinue ? 102 : 120
        if (this.percent >= end) {
          clearInterval(this.interval)
          this.$emit('done')
        } else {
          if (this.isContinue) {
            this.percent += 0.1
          } else this.percent += 1
        }
      },
      this.isContinue ? 30 : 32
    )
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
}
</script>

<style lang="scss">
:root {
  --dash-offset: 440;
  --dash-offset-end: 0;
  --rotate: 0deg;
  --rotateEnd: 360deg;
}
.progress-bar {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 40px auto;
  svg {
    width: 150px;
    height: 150px;
    transform: rotate(270deg);
  }

  circle {
    width: 100%;
    height: 100%;
    fill: transparent;
    stroke-width: 8;
    stroke: rgba($color: #fff, $alpha: 0.1);
    transform: translate(5px, 5px);

    &:nth-child(2) {
      stroke-dasharray: 440;
      stroke-dashoffset: var(--dash-offset);
      stroke: url(#linear);
      animation: run 3s linear forwards;

      @keyframes run {
        100% {
          stroke-dashoffset: var(--dash-offset-end);
        }
      }
    }
  }

  &__dot {
    position: absolute;
    inset: 5px;
    z-index: 10;
    animation: dot 3s linear forwards;

    @keyframes dot {
      0% {
        transform: rotate(var(--rotate));
      }

      100% {
        transform: rotate(var(--rotateEnd));
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #92e5a5;
    }
  }

  &__dot-start {
    position: absolute;
    inset: 5px;
    z-index: 10;
    &::before {
      content: '';
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #92e5a5;
    }
  }

  &__number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #ffffff;
    font-family: 'Manrope Light';
    font-style: normal;
  }
}
</style>
