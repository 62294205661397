<template>
  <QuizFieldWrapper :title="fieldTitle" :description="fieldDescription">
    <template #default>
      <div class="quiz__circles quiz__circles--gender">
        <div
          class="quiz__circle quiz__circle--md"
          v-for="g in question.answers"
          :key="g.value"
        >
          <input
            type="radio"
            name="gender"
            :id="g.value"
            class="quiz__checkbox"
            :value="g.value"
            v-model="proxyValue"
          />
          <label :for="g.value" class="quiz__circle-wrapper">
            <div
              class="quiz__circle-top quiz__circle-top--md"
              :class="g.value === 3 && 'non-binary'"
              v-html="g.img"
            ></div>
            <div class="quiz__circle-bottom">{{ g.text }}</div>
          </label>
        </div>
      </div>
    </template>
  </QuizFieldWrapper>
</template>

<script>
import QuizFieldWrapper from './QuizFieldWrapper.vue'

export default {
  name: 'QuizGenderField',
  components: { QuizFieldWrapper },
  props: ['fieldTitle', 'fieldDescription', 'question', 'value'],
  emits: ['next', 'fieldChange'],
  computed: {
    proxyValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('fieldChange', newValue)
      },
    },
  },
}
</script>
