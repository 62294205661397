<template>
  <LeadsCreateAccount v-if="isLeads" />
  <!-- <CreateAccountForm :statistic_id="quiz.statistic_id" v-else /> -->
</template>

<script>
import CreateAccountForm from '../CreateAccountForm.vue'
import { setQuiz } from '@/services/quiz.service'
import { initFacebook } from '@/utils/facebookConfig'
import LeadsCreateAccount from './LeadsCreateAccount.vue'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'QuizCreateAccount',
  components: { CreateAccountForm, LeadsCreateAccount },
  props: ['quizId'],
  created() {
    localStorage.setItem('isQuiz', true)
    try {
      setQuiz({ statistic_id: this.quiz.statistic_id, action: 'complete_quiz' })
    } catch (err) {
      console.log(err)
    }
    if (this.token) {
      this.$router.push(`/quiz-dynamic-results/${this.quizId}`)
      return
    }
    if (!this.isLeads) {
      // if (this.$route.params.slug) {
      //   this.$router.push(`/quiz-dynamic-results/${this.quizId}`)
      //   return
      // }
      this.$router.push(
        `/create-email-account?quizId=${this.quizId}&isCustomQuiz=${this.isCustomQuiz}`
      )
    }
  },
  computed: {
    ...mapState('quiz', ['quiz']),
    ...mapState(['token']),
    ...mapGetters('quiz', ['isCustomQuiz']),
    isLeads() {
      return this.$route.name === 'LeadsQuiz' || this.quiz.type === 'Lead Quiz'
    },
  },
  async mounted() {
    fbq('trackCustom', 'FinishedQuiz')
  },
}
</script>

<style lang="scss">
.account-title--quiz {
  margin-bottom: 20px;
  text-align: center;
}
.login--quiz {
  padding-top: 30px;
}
.quiz__description--quiz {
  margin-bottom: 72px;
}

.login__link {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.account__footer {
  max-width: 243px;
  padding: 10px 0 20px;
}
</style>
