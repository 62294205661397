<template>
  <div
    v-if="isQuizDataLoading || isQuizLoading || isSingleQuizLoading"
    class="quiz-loading"
  >
    <a-spin />
  </div>
  <div v-else class="quiz">
    <quiz-header />
    <quiz-custom-content
      v-if="
        (quiz.type === 'Lead Quiz' || quiz.type === 'Direct Sales') &&
        progress - 1 < totalTest
      "
    />
    <quiz-content
      v-else-if="quiz.type === 'Predefined' && progress - 1 < totalTest"
    />

    <quiz-create-account
      v-else-if="progress - 1 === totalTest"
      :quizId="quiz.quiz"
    />
  </div>
</template>

<script>
import QuizHeader from '../components/quiz/QuizHeader.vue'
import QuizContent from '../components/quiz/QuizContent.vue'
import QuizCustomContent from '../components/quiz/QuizCustomContent.vue'
import { mapActions, mapState } from 'vuex'
import QuizCreateAccount from '../components/quiz/QuizCreateAccount.vue'
import { initFacebook, loadFacebookSDK } from '@/utils/facebookConfig'
import { setQuiz } from '@/services/quiz.service'

export default {
  components: { QuizHeader, QuizContent, QuizCreateAccount, QuizCustomContent },
  name: 'Quiz',
  created() {
    this.fetchQuizFields()
    let params = { platform: 'web' }
    if (this.$route.name === 'SingleQuiz') {
      this.fetchSingleQuiz(this.$route.params.slug)
      return
    }
    if (this.$route.name === 'LeadsQuiz') {
      params = { ...params, rotation_slug: this.$route.params.slug }
    }
    if (this.$route.name === 'UserQuiz') {
      params = { ...params, rotation_slug: this.$route.params.slug }
    }
    this.fetchQuiz(params)
  },
  async mounted() {
    await this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
    await this.initFacebook()
  },
  computed: {
    ...mapState('quiz', [
      'progress',
      'totalTest',
      'quizData',
      'quiz',
      'isQuizLoading',
      'isQuizDataLoading',
      'isSingleQuizLoading',
    ]),
    ...mapState(['token']),
  },
  methods: {
    ...mapActions('quiz', [
      'fetchQuizFields',
      'fetchQuiz',
      'fetchCustomQuiz',
      'fetchSingleQuiz',
      'quiz',
    ]),
    initFacebook,
    loadFacebookSDK,
    handleBeforeUnload() {
      setQuiz({
        statistic_id: this.quiz.statistic_id,
        action: 'bounced_block',
        block_id: this.quizData.quiz_block[this.progress - 1].id,
      })
    },
  },
  requestSent: false,
  beforeRouteLeave(to, from, next) {
    if (
      !this.$options.requestSent &&
      (this.quiz.type === 'Direct Sales' || this.quiz.type === 'Lead Quiz') &&
      this.progress < this.totalTest
    ) {
      this.handleBeforeUnload()
      this.$options.requestSent = true
    }
    next()
  },
}
</script>

<style scoped>
.quiz-loading {
  display: grid;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 112px);
}
</style>
