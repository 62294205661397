<template>
  <div class="cur-step">
    <ul class="cur-step__container">
      <li
        v-for="item in data"
        :key="item.id"
        class="cur-step__item"
        :class="{ active: item.id <= current }"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LoaderList',
  data() {
    return {
      current: 0,
      data: [
        { title: 'Analyzing your goals', id: 1 },
        { title: 'Analyzing your demographics', id: 2 },
        { title: 'Preparing your personal plan', id: 3 },
      ],
    }
  },
  mounted() {
    setTimeout(() => {
      setInterval(() => {
        this.current++
      }, 900)
    }, 50)
  },
}
</script>

<style lang="scss">
.cur-step {
  margin-top: 30px;

  &__container {
    display: grid;
    gap: 40px;
  }
  &__item {
    display: block;
    width: max-content;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    position: relative;
    opacity: 0.4;
    transition: all 300ms ease-in-out;
    margin-left: 40px;

    &::before {
      content: '';
      position: absolute;
      display: flex;
      width: 24px;
      height: 24px;
      background: transparent;
      border-radius: 24px;
      border: 1px solid rgba(#fff, 0.2);
      left: -38px;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: -26px;
      height: 46px;
      width: 1px;
      background: rgba(#fff, 0.2);
      bottom: -42px;
    }

    &:last-child {
      &::after {
        height: 0;
      }
    }

    &.active {
      opacity: 1;
      &::before {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.218 9.3479L10.3919 16.6522L7.7832 14.0436' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &::after {
        background: #fff;
      }
    }
  }
}
</style>
