<template>
  <div class="login login--quiz space-between">
    <div class="login__form-wrapper account-form-wrapper">
      <div class="login__title account-title account-title--quiz">
        Create Account
      </div>
      <div class="quiz__description quiz__description--quiz">
        Sign Up Below To Get Your Personalized Mind Improvement Plan
      </div>
      <!-- <div class="login__wrapper-social">
        <button
          @click="logInWithFacebook"
          class="login__link login__link--facebook"
        >
          Sign up with Facebook
          <span class="icon">
            <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_641_9201)"><path d="M12.77 7.462H8.5v-1.91a1 1 0 011-1.1h3V.512H8.17c-3.93 0-4.67 2.93-4.67 4.81v2.15h-3v4h3v12h5v-12h3.85l.42-4.01z" stroke="#fff" stroke-linejoin="round"/></g><defs><clipPath id="clip0_641_9201"><path fill="#fff" d="M0 0h13.27v23.96H0z"/></clipPath></defs></svg>
          </span>
        </button>
      </div> -->
      <div class="login__wrapper-social">
        <GoogleLogin :callback="callback" popupType="TOKEN">
          <button class="login__link login__link--google">
            Sign up with Google
            <span class="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_641_9397)"><path d="M21.5 16.44v5.09c-4.53 2.29-11.59 2.94-15.92 0a11.49 11.49 0 01-5.07-9.74 11.82 11.82 0 016.65-9.93C11.49-.33 18.04.08 21.85 3.24l-2.24 2.3-1.46.41.77-1.41a9.38 9.38 0 00-3.79-2.38 7.94 7.94 0 00-6 .53 9.49 9.49 0 00-4.81 10.35 10.46 10.46 0 009.2 8.8c1.434.139 2.881.034 4.28-.31 1-.31.74-.4.74-1v-4H14l2.45-2H23l-1.5 1.91z" stroke="#fff" stroke-linejoin="round"/></g><defs><clipPath id="clip0_641_9397"><path fill="#fff" d="M0 0h23.51v24H0z"/></clipPath></defs></svg>
            </span>
          </button>
        </GoogleLogin>
      </div>
      <div class="login__wrapper-social">
        <router-link :to="getUrl" class="login__link login__link--facebook">
          Sign up with Email
          <span class="icon">
            <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 .5H2A1.5 1.5 0 00.5 2v11A1.5 1.5 0 002 14.5h18a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0020 .5z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 1L11 9 1 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </span>
        </router-link>
      </div>
      <!-- <div class="login__wrapper-social">
        <router-link :to="getUrl" class="login__link login__link--apple">
          Sign up with Apple
          <span class="icon">
            <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_3547_6014)" stroke="#fff" stroke-linejoin="round"><path d="M15.32 13.29a4.9 4.9 0 012.36-4.15 5.12 5.12 0 00-4-2.16c-1.7-.17-3.31 1-4.17 1-.86 0-2.19-1-3.6-.95A5.33 5.33 0 001.4 9.76c-1.92 3.34-.49 8.28 1.38 11 .92 1.33 2 2.81 3.44 2.76 1.44-.05 1.91-.89 3.57-.89s2.14.89 3.6.86c1.46-.03 2.43-1.35 3.34-2.67a12.43 12.43 0 001.51-3.1 4.83 4.83 0 01-2.92-4.43zM12.76 4.17A5.05 5.05 0 0013.95.5a5.2 5.2 0 00-3.39 1.74A4.86 4.86 0 009.34 5.8a4.3 4.3 0 003.42-1.63v0z"/></g><defs><clipPath id="clip0_3547_6014"><path fill="#fff" d="M0 0h18.75v24H0z"/></clipPath></defs></svg>
          </span>
        </router-link>
      </div> -->
      <div class="account-text">
        <span>Already have an account?</span>
        <router-link :to="loginRoute" class="sign-up"> Sign In </router-link>
      </div>
    </div>
    <div class="account__footer">
      <div class="account-text">
        By continuing, you agree to our
        <div class="terms-policy">
          <router-link :to="{ name: 'TermsAndConditions' }">
            Terms & Conditions
          </router-link>
          <span>and</span>
          <router-link :to="{ name: 'PrivacyAndPolicy' }"
            >Privacy Policy</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  initFacebook,
  loadFacebookSDK,
  loadFacebookPixel,
} from '@/utils/facebookConfig'
import { notification } from 'ant-design-vue'
import { userAuth } from '@/services/user.service'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { putLead } from '@/services/lead.service'

export default {
  name: 'CreateAccountForm',
  props: {
    statistic_id: {
      type: String,
      default: '',
    },
    apple: Boolean,
  },
  data() {
    return {
      loginRoute: `/login${window.location.search}`,
    }
  },
  computed: {
    ...mapGetters('quiz', ['isCustomQuiz']),
    ...mapState('quiz', ['quizData', 'quizForm']),
    getUrl() {
      console.log('createAccountForm', this.quizData)
      const s = this.statistic_id ? { statistic_id: this.statistic_id } : {}
      return {
        name: 'Registration',
        query: { ...this.$route.query, ...s },
      }
    },
    isLead() {
      return this.$route.query.u === 'lead'
    },
  },
  async mounted() {
    await loadFacebookSDK(document, 'script', 'facebook-jssdk')
    await initFacebook()
    loadFacebookPixel()
    window.fbAsyncInit()
  },
  methods: {
    ...mapMutations(['SET_TOKEN']),
    ...mapMutations('quiz', ['SET_QUIZ_FORM']),
    ...mapActions(['fetchUser']),
    async logInWithFacebook() {
      const that = this
      window.FB.login(function (response) {
        if (response.authResponse) {
          if (this.isLead) {
            const statistic_id = this.$route.query.statistic_id
            putLead({
              statistic_id,
              auth: 'facebook',
              id_token: response.authResponse.accessToken,
            }).then(async (data) => {
              that.redirect(data)
            })
          } else {
            userAuth('facebook', {
              id_token: response.authResponse.accessToken,
              is_ios: false,
              platform: 'web',
            }).then(async (data) => {
              that.redirect(data)
            })
          }
        } else {
          notification.error({
            message: 'User cancelled login or did not fully authorize.',
          })
          console.log('User cancelled login or did not fully authorize.')
        }
      })
      return false
    },
    callback(response) {
      if (this.isLead) {
        const statistic_id = this.$route.query.statistic_id
        putLead({
          statistic_id,
          auth: 'google',
          id_token: response.access_token,
        }).then((res) => {
          this.redirect(res)
        })
      } else {
        userAuth('googleWeb', {
          id_token: response.access_token,
          is_ios: false,
        }).then((res) => {
          this.redirect(res)
        })
      }
    },
    async redirect(data) {
      fbq('track', 'CompleteRegistration')
      this.SET_TOKEN(data.token)
      const isQuiz = localStorage.getItem('isQuiz')
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect)
      } else if (isQuiz) {
        if (this.isCustomQuiz) {
          this.SET_QUIZ_FORM({ ...this.quizForm, isQuizCompleted: true })
          localStorage.setItem('quizForm', JSON.stringify(this.quizForm))
          this.$router.push('/quiz-dynamic-results/' + this.quizData.slug)
        } else {
          this.$router.push('/quiz-results')
        }
      } else {
        const data = await this.fetchUser()
        if (!data.gender && !data.goal) {
          this.$router.push('/quiz')
        } else window.location.href = '/home'
      }
    },
  },
}
</script>

<style lang="scss">
.account-title--quiz {
  margin-bottom: 20px;
  text-align: center;
}
.login--quiz {
  padding-top: 30px;
}
.quiz__description--quiz {
  margin-bottom: 72px;
}

.login__link {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.account__footer {
  max-width: 243px;
  padding: 10px 0 20px;
}

.sign-up {
  margin-top: 8px;
}
</style>
