<template>
  <QuizFieldWrapper :title="fieldTitle" :description="fieldDescription">
    <template #default>
      <div class="quiz__circles quiz__circles--age">
        <div
          class="quiz__circle quiz__circle--md"
          v-for="a in question.answers"
          :key="a.value"
        >
          <input
            type="radio"
            name="age"
            :id="a.value"
            class="quiz__checkbox"
            :value="a.value"
            v-model="proxyValue"
          />
          <label :for="a.value" class="quiz__circle-wrapper">
            <div class="quiz__circle-top quiz__circle-top--md">
              {{ a.text }}
            </div>
            <div class="quiz__circle-bottom">{{ a.label }}</div>
          </label>
        </div>
      </div>
    </template>
  </QuizFieldWrapper>
</template>

<script>
import QuizFieldWrapper from './QuizFieldWrapper.vue'

export default {
  name: 'QuizAgeField',
  components: { QuizFieldWrapper },
  props: ['fieldTitle', 'fieldDescription', 'question', 'value'],
  emits: ['next', 'fieldChange'],
  computed: {
    proxyValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('fieldChange', newValue)
      },
    },
  },
}
</script>
