<template>
  <div class="quiz__content" :class="`quiz__content--${size}`">
    <div>
      <div class="quiz__title">{{ title }}</div>
      <p class="quiz__description quiz__description--sm">
        {{ description }}
      </p>
    </div>
    <slot></slot>

    <slot name="actions"></slot>
  </div>
</template>

<script>
export default {
  name: 'QuizFieldWrapper',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
}
</script>

<style lang="scss" scoped>
.quiz__content {
  @media (max-width: 768px) {
    padding-bottom: 100px;
    height: calc(100vh - 63px);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-progress-circle-trail {
    stroke: #ffffff1a;
  }

  ::v-deep .swiper-container {
    display: flex;
    flex-direction: column-reverse;
  }

  ::v-deep .swiper-pagination {
    display: flex;
    margin-top: 36px;
    justify-content: center;
    column-gap: 8px;
  }

  ::v-deep .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: rgba(#fff, 0.1);
    border-radius: 50%;
    display: block;
    cursor: pointer;
  }

  ::v-deep .swiper-pagination-bullet-active {
    background: #ffffff;
  }
}

::v-deep .quiz-btn__container {
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: #4e8b72;

    .quiz-btn {
      margin: 15px 0;
    }
  }
}
</style>
