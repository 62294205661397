<template>
  <QuizFieldWrapper :title="fieldTitle" :description="fieldDescription">
    <template #default>
      <div>
        <img :src="question.info_image" alt="">
      </div>
    </template>
    <template #actions>
      <div class="quiz-btn__container">
        <button
          :disabled="proxyValue"
          class="quiz__btn btn-reset quiz-btn quiz-btn--md quiz-btn--white"
          @click="$emit('next')"
        >
          Continue
        </button>
      </div>
    </template>
  </QuizFieldWrapper>
</template>

<script>
import QuizFieldWrapper from './QuizFieldWrapper.vue'

export default {
  name: 'QuizInfoField',
  components: { QuizFieldWrapper },
  props: ['fieldTitle', 'fieldDescription', 'question'],
  emits: ['next'],
}
</script>
