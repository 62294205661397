<template>
  <QuizFieldWrapper :title="fieldTitle" :description="fieldDescription">
    <template v-slot:default>
      <div class="quiz__circles quiz__circles--choice">
        <div class="quiz__circle quiz__circle--md">
          <input
            type="checkbox"
            :name="question.name"
            :id="question.id + 'yes'"
            class="quiz__checkbox"
            :value="true"
            @change="proxyValue = true"
          />
          <label :for="question.id + 'yes'" class="quiz__circle-wrapper">
            <div class="quiz__circle-top quiz__circle-top--md">
              <img sgv-inline src="@/static/quiz/icons/yes.svg" />
            </div>
            <div class="quiz__circle-bottom quiz__circle-bottom--lg">Yes</div>
          </label>
        </div>
        <div class="quiz__circle quiz__circle--md">
          <input
            type="checkbox"
            :name="question.name"
            :id="question.id + 'no'"
            class="quiz__checkbox"
            :value="false"
            @change="proxyValue = false"
          />
          <label :for="question.id + 'no'" class="quiz__circle-wrapper">
            <div class="quiz__circle-top quiz__circle-top--md">
              <img sgv-inline src="@/static/quiz/icons/no.svg" />
            </div>
            <div class="quiz__circle-bottom quiz__circle-bottom--lg">No</div>
          </label>
        </div>
      </div>
    </template>
  </QuizFieldWrapper>
</template>

<script>
import QuizFieldWrapper from './QuizFieldWrapper.vue'
import { mapState } from 'vuex'

export default {
  name: 'QuizYesNoField',
  components: { QuizFieldWrapper },
  props: ['fieldTitle', 'fieldDescription', 'question', 'value'],
  emits: ['fieldChange'],
  computed: {
    ...mapState('quiz', {
      quizForm: 'quizForm',
    }),
    proxyValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('fieldChange', value)
      },
    },
  },
}
</script>
