<template>
  <div class="quiz-header">
    <router-link class="quiz-header__head" to="/">
      <h2 class="quiz-header__title">Mental Future</h2>
    </router-link>
    <div class="quiz-header__body">
      <button @click="onBack" class="quiz-header__back-btn">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Previous question"
        />
      </button>
      <a-progress
        class="quiz-header__progress"
        :percent="progressValue"
        :showInfo="false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'QuizHeader',
  computed: {
    ...mapState('quiz', ['progress']),
    ...mapGetters('quiz', ['progressValue']),
    // isLeads() {
    //   return this.$route.query.t === 'LeadsQuiz'
    // },
  },
  methods: {
    ...mapMutations('quiz', ['SET_PROGRESS']),
    onBack() {
      if (this.progress === 1) {
        this.$router.back()
      } else {
        this.SET_PROGRESS(this.progress - 1)
      }
    },
  },
}
</script>

<style lang="scss">
.quiz-header {
  padding: 36px 36px 0;

  @media (max-width: 500px) {
    padding: 16px 24px 0;
  }

  &__head {
    @media (max-width: 500px) {
      display: none;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 64px;

    @media (max-width: 500px) {
      margin-bottom: 32px;
    }
  }

  &__title {
    font-family: var(--run);
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    color: var(--color-white);
  }

  &__body {
    position: relative;
    display: flex;

    @media (min-width: 501px) {
      justify-content: center;
    }

    @media (max-width: 500px) {
      align-items: flex-start;
      align-items: center;
    }
  }

  &__back-btn {
    cursor: pointer;
    background-color: inherit;
    border: none;
    padding: 0;
    min-width: 16px;
    height: 24px;

    @media (min-width: 501px) {
      position: absolute;
      left: 0;
      top: -2px;
    }

    @media (max-width: 500px) {
      margin-right: 8px;
    }
  }

  &__progress {
    max-width: 327px;

    @media (max-width: 500px) {
      margin: 0 auto;
    }

    .ant-progress-inner {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .ant-progress-bg {
      background-color: rgba(#fff, 0.5);
    }
  }
}
</style>
