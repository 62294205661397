import {
  age,
  ageLabel,
  challenges,
  challengesIcon,
  emotions,
  emotionsIcon,
  gender,
  genderIcon,
  goal,
  goalIcon,
  typicalDay,
  typicalDayImage,
  goalNewIcons,
  emotionsIconNew,
  challengesIconNew,
} from './quiz-data'

export const types = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  CUSTOM: 'custom',
  RANGE: 'range',
  TESTIMONIAL: 'testimonial',
}

export const quizIds = {
  QUIZ_1: 1,
  QUIZ_2: 2,
  QUIZ_3: 3,
  QUIZ_4: 4,
  QUIZ_5: 5,
  QUIZ_7: 7,
}

export const names = {
  GENDER: 'gender',
  GOAL: 'goal',
  AGE: 'age',
  TYPICAL_DAY: 'typical-day',
  OUR_METHOD: 'our-method',
  TENSION: 'tension',
  ENERGY: 'energy',
  SLEEP: 'sleep',
  MOTIVATION: 'motivation',
  EMOTIONS: 'emotions',
  CHALLENGES: 'challenges',
  LOADER_1: 'loader1',
  ONE_LAST: 'one-last',
  LOADER_2: 'loader2',
  LOADER_3: 'loader3',
  TRACKING: 'tracking',
  THOUGHTS_1: 'thoughts1',
  THOUGHTS_2: 'thoughts2',
  GUILT: 'guilt',
  CONCENTRATION: 'concentration',
  MOOD: 'mood',
  FEAR: 'fear',
  EMAIL: 'email',
  FEELING_SAD: 'feeling-sad',
  ENERGY_RATE: 'energy_rate',
  APPETITE: 'appetite',
  EFFECTS: 'effects',
  THOUGHTS_NERVOUS: 'thoughts_nervous',
  NEGATIVITY: 'negativity',
  WORRY: 'worry',
  FEAR_AFRAID: 'fear_afraid',
  HIGHER_ENERGY: 'higher_energy',
  CONFIDENCE: 'confidence',
  HIGHER_MOTIVATION: 'higher_motivation',
  HIGHER_HAPPINESS: 'higher_happiness',
  HIGHER_SLEEP: 'higher_sleep',
  STRESS: 'stress',
  CONTENT_TYPE: 'content_type',
  TESTIMONIAL: 'testimonial',
  MENTAL_INFO: 'mental_info',
  MENTAL_RECAP: 'mental_recap',
  FEEL: 'feel',
}

export const booleanQuestions = [
  names.MOTIVATION,
  names.THOUGHTS_1,
  names.THOUGHTS_2,
]

export const rangeQuestions = [
  names.TENSION,
  names.GUILT,
  names.CONCENTRATION,
  names.MOOD,
  names.FEELING_SAD,
  names.ENERGY_RATE,
  names.APPETITE,
  names.EFFECTS,
  names.THOUGHTS_NERVOUS,
  names.NEGATIVITY,
  names.WORRY,
  names.FEAR_AFRAID,
  names.FEEL,
]

export const higherAttributeUsers = [
  'higher_confidence',
  'higher_motivation',
  'happiness',
  'stress',
]

export const questionNineTitles = {
  [goal.INCREASE_CONFIDENCE]: 'Boost your confidence with Mental Future',
  [goal.INCREASE_ENERGY]: 'Increase your energy with Mental Future',
  [goal.INCREASE_HAPPINESS]: 'Feel joyful with Mental Future',
  [goal.INCREASE_MOTIVATION]: 'Get motivated with Mental Future',
  [goal.REDUCE_STRESS]: 'Relax with Mental Future',
  [goal.SLEEP_BETTER]: 'Fall asleep with Mental Future',
}

export const questionImprovementTitles = {
  [goal.INCREASE_CONFIDENCE]: 'Feel confident',
  [goal.INCREASE_ENERGY]: 'Feel energetic',
  [goal.INCREASE_HAPPINESS]: 'Feel joyful',
  [goal.INCREASE_MOTIVATION]: 'Feel motivated',
  [goal.REDUCE_STRESS]: 'Feel relaxed',
  [goal.SLEEP_BETTER]: 'Improve sleep',
}

export const imageTypes = {
  IMAGE: 'image',
  SVG: 'svg',
}

const sleepTexts = [
  'Less than 5 hours',
  '5-6 hours',
  '7-8 hours',
  'More than 8 hours',
]
const energyTexts = [
  'My energy levels do not fluctuate',
  'I drag before meals',
  'I feel sleepy after lunch',
  'I have low energy throughout the whole day',
]
const times = [
  'Less than 10 minutes',
  'Less than 30 minutes',
  '30-60 minutes',
  'More than 1 hour',
]

const fearValues = ['Never', 'Sometimes', 'Often', 'Very Often']
const higherEnergyLevels = [
  'Work Performance',
  'Working Out',
  'Personal Relationships',
  'Hobbies',
  'Other',
]
const higherAttributeRepeat = [
  'Family',
  'Working Out',
  'Personal Relationships',
  'Work Performance',
  'Other',
]
const sleepHabits = [
  'Go to bed earlier',
  'Fall asleep faster',
  'Get more hours sleeping',
  'Not waking up so often',
  'Sleep less',
]
const contentTypes = [
  'Meditation',
  'CBT',
  'Sleepcasts',
  'Nature Sounds',
  'Music',
  'I am not sure',
]

const questionGender = {
  name: names.GENDER,
  type: types.RADIO,
  title: `Let's Create Your Mind Profile`,
  sub_title: 'What gender best describes you?',
  answers: [
    {
      text: 'MALE',
      img: genderIcon[gender.MALE],
    },
    {
      text: 'FEMALE',
      img: genderIcon[gender.FEMALE],
    },
    {
      text: 'NON-BINARY',
      img: genderIcon[gender.NON_BINARY],
    },
  ],
  model: 'gender',
}
const questionGoal = {
  name: names.GOAL,
  type: types.CHECKBOX,
  title: 'Goal',
  sub_title: 'What are your primary goals?',
  answers: Object.entries(goal).map(([k, v]) => ({
    text: v,
  })),
  model: 'goal',
}
const questionGoal2 = {
  name: names.GOAL,
  type: types.CHECKBOX,
  title: 'Goal',
  sub_title: 'What are your primary goals?',
  sub_text: '(select all that apply)',
  answers: Object.entries(goal).map(([k, v]) => ({
    text: v,
    img: goalNewIcons[v],
    imgType: imageTypes.IMAGE,
  })),
  model: 'goal',
}
const questionAge = {
  name: names.AGE,
  type: types.RADIO,
  title: 'age',
  sub_title: 'What is your age?',
  answers: Object.entries(age).map(([k, v]) => ({
    text: v,
    label: ageLabel[v],
  })),
  model: 'age',
}

const questionFear = {
  name: names.FEAR,
  type: types.RADIO,
  title: 'fear',
  sub_title:
    'Do you experience strong fear that causes panic, shortness of breath, chest pains, a pounding heart, sweating, shaking, nausea, dizziness, and/or fear of dying?',
  answers: fearValues.map((i) => ({ text: i })),
  model: 'fear',
}

const questionTypicalDay = {
  name: names.TYPICAL_DAY,
  type: types.RADIO,
  title: 'Typical Day',
  sub_title:
    () => `We offer a more personalized approach depending on your current
    lifestyle.`,
  title_small: 'Please describe your typical day',
  answers: Object.entries(typicalDay).map(([k, v]) => ({
    text: v,
    img: typicalDayImage[v],
  })),
  model: 'typical_day',
}

const questionWorkplace = {
  name: names.TYPICAL_DAY,
  type: types.RADIO,
  title: 'workplace',
  sub_title: (
    gender,
    age,
    goal
  ) => `${gender} in their ${age} who want to beat ${goal}
  require a more personalized approach
  depending on their current lifestyle`,
  title_small: 'Please describe your typical day',
  answers: Object.entries(typicalDay).map(([k, v]) => ({
    text: v,
    img: typicalDayImage[v],
  })),
  model: 'typical_day',
}

const questionOurMethod = {
  name: names.OUR_METHOD,
  type: types.CUSTOM,
  component: 'OurMethod',
}

const questionTension = {
  name: names.TENSION,
  type: types.RANGE,
  title: 'tension',
  sub_title: `Over the past 2 weeks, how often have you <br/>
     been bothered by the following problem:`,
  title_capitalize: 'HAVING TROUBLE RELAXING',
  model: 'tension',
}

const questionGuilt = {
  name: names.GUILT,
  type: types.RANGE,
  title: 'Guilt',
  sub_title: `Over the past 2 weeks, how often have you <br/>
  been bothered by the following problems:`,
  title_capitalize:
    'Feeling bad about yourself - or that you are a failure or have let yourself down.',
  model: 'guilt',
}

const questionConcentration = {
  name: names.CONCENTRATION,
  type: types.RANGE,
  title: 'Concentration',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize:
    'Trouble concentrating on things, <br/> such as reading the newspaper or watching television.',
  model: 'concentration',
}

const questionMood = {
  name: names.MOOD,
  type: types.RANGE,
  title: 'Mood',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize: 'Little interest or pleasure in<br/> doing things.',
  model: 'mood',
}

const questionFeelingSad = {
  name: names.FEELING_SAD,
  type: types.RANGE,
  title: 'Feeling Sad',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize: 'Feeling down, depressed or hopeless.',
  model: 'feeling_sad',
}

const questionEnergyRate = {
  name: names.ENERGY_RATE,
  type: types.RANGE,
  title: 'Energy',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize: 'Feeling tired or having little energy.',
  model: 'energy_rate',
}

const questionAppetite = {
  name: names.APPETITE,
  type: types.RANGE,
  title: 'Apetite',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize: 'Poor appetite or overeating.',
  model: 'appetite',
}

const questionEffects = {
  name: names.EFFECTS,
  type: types.RANGE,
  title: 'Effects',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize:
    'How difficult have these problems<br/> made it for you to do your work or<br/> get along with other people?',
  model: 'effects',
}

const questionThoughtsNervous = {
  name: names.THOUGHTS_NERVOUS,
  type: types.RANGE,
  title: 'Thoughts',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize: 'Feeling nervous, anxious or on edge',
  model: 'thoughts_nervous',
}

const questionThoughtsNegativity = {
  name: names.THOUGHTS_NERVOUS,
  type: types.RANGE,
  title: 'Negativity',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize: 'Not being able to stop or<br/> control worrying',
  model: 'negativity',
}

const questionWorry = {
  name: names.WORRY,
  type: types.RANGE,
  title: 'Worry',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize: 'Worrying too much about<br/> different things',
  model: 'worry',
}

const questionFeel = {
  name: names.FEEL,
  type: types.RANGE,
  title: 'How do you feel?',
  sub_title: `How optimistic are you feeling about<br/>
  getting over your struggles?`,
  model: 'feel',
}

const questionFearAfraid = {
  name: names.FEAR_AFRAID,
  type: types.RANGE,
  title: 'Fear',
  sub_title: `Over the past 2 weeks, how often have you
  been bothered by the following problems:`,
  title_capitalize: 'Feeling afraid as if something<br/> awful might happen',
  model: 'fear_afraid',
}

const questionEnergy = {
  name: names.ENERGY,
  type: types.RADIO,
  title: 'Energy',
  sub_title: `Are you able to maintain your energy
    <br/>during the day?`,
  answers: energyTexts.map((i) => ({ text: i })),
  model: 'energy',
}

const questionSleep = {
  name: names.SLEEP,
  type: types.RADIO,
  title: 'Sleep',
  sub_title: `Getting just enough sleep is essential for <br/>
    feeling healthier`,
  title_small: 'How long do you normally sleep?',
  answers: sleepTexts.map((i) => ({ text: i })),
  model: 'sleep',
}

const questionMotivation = {
  name: names.MOTIVATION,
  type: types.RADIO,
  title: 'Motivation',
  sub_title: 'Do you relate to the statement below?',
  comment: `“I often require external motivation to
    keep going. I can easily give up when I
    feel stressed.”`,
  answers: [
    {
      text: 'YES',
      img: require('@/assets/icons/like.svg'),
    },
    {
      text: 'NO',
      img: require('@/assets/icons/dislike.svg'),
    },
  ],
  model: 'no_motivation',
}

const questionEmotions = {
  name: names.EMOTIONS,
  type: types.CHECKBOX,
  title: 'Emotions',
  sub_title: 'Which of these emotions get in your way?',
  answers: Object.entries(emotions).map(([k, v]) => ({
    text: v,
    img: emotionsIcon[v],
    imgType: imageTypes.IMAGE,
  })),
  model: 'emotions',
}

const questionEmotions2 = {
  name: names.EMOTIONS,
  type: types.CHECKBOX,
  title: 'Emotions',
  sub_title: 'Which of these emotions get in your way?',
  sub_text: '(select all that apply)',
  answers: Object.entries(emotions).map(([k, v]) => ({
    text: v,
    img: emotionsIconNew[v],
    imgType: imageTypes.IMAGE,
  })),
  model: 'emotions',
}

const questionChallenges = {
  name: names.CHALLENGES,
  type: types.CHECKBOX,
  title: 'Challenges',
  sub_title: 'What are your current challenges?',
  answers: Object.entries(challenges).map(([k, v]) => ({
    text: v,
    img: challengesIcon[v],
    imgType: imageTypes.IMAGE,
  })),
  model: 'challenges',
}

const questionChallenges2 = {
  name: names.CHALLENGES,
  type: types.CHECKBOX,
  title: 'Challenges',
  sub_title: 'What are your current challenges?',
  sub_text: '(select all that apply)',
  answers: Object.entries(challenges).map(([k, v]) => ({
    text: v,
    img: challengesIconNew[v],
    imgType: imageTypes.IMAGE,
  })),
  model: 'challenges',
}

const questionHigherEnergy = {
  name: names.HIGHER_ENERGY,
  type: types.CHECKBOX,
  title: 'Energy',
  sub_title: 'Having enough energy is essential for <br/> daily performance',
  comment: `What would higher energy levels<br/> benefit you most in?`,
  answers: higherEnergyLevels.map((i) => ({ text: i })),
  model: 'higher_energy_levels',
}
const questionConfidence = {
  name: names.CONFIDENCE,
  type: types.CHECKBOX,
  title: 'Confidence',
  sub_title: 'Having higher confidence is essential<br /> to achieving success',
  comment: `What area of your daily life would higher<br/> confidence be most beneficial in?`,
  answers: higherAttributeRepeat.map((i) => ({ text: i })),
  model: 'higher_confidence',
}
const questionHigherMotivation = {
  name: names.HIGHER_MOTIVATION,
  type: types.CHECKBOX,
  title: 'Motivation',
  sub_title: 'Having a higher motivation crucial to<br/> achieving your goals',
  comment: `What area of your daily life would higher<br/> motivation be most beneficial in?`,
  answers: higherAttributeRepeat.map((i) => ({ text: i })),
  model: 'higher_motivation',
}
const questionHigherHappiness = {
  name: names.HIGHER_HAPPINESS,
  type: types.CHECKBOX,
  title: 'Happiness',
  sub_title: 'Feeling joyful will increase your overall<br/> quality of life',
  comment: `How would feeling joyful<br/> benefit you most?`,
  answers: higherAttributeRepeat.map((i) => ({ text: i })),
  model: 'happiness',
}
const questionHigherSleep = {
  name: names.HIGHER_SLEEP,
  type: types.CHECKBOX,
  title: 'Sleep',
  sub_title:
    'Getting just enough sleep is essential for<br/> feeling healthier',
  comment: `What do you want to improve in <br/>your sleeping habits`,
  answers: sleepHabits.map((i) => ({ text: i })),
  model: 'sleep_habits',
}
const questionStress = {
  name: names.STRESS,
  type: types.CHECKBOX,
  title: 'Stress',
  sub_title:
    'Reducing stress will massively improve your<br/> mental health and quality of life',
  comment: `What areas would reducing stress<br/> benefit most in your life?`,
  answers: higherAttributeRepeat.map((i) => ({ text: i })),
  model: 'stress',
}
const questionContentType = {
  name: names.CONTENT_TYPE,
  type: types.CHECKBOX,
  title: 'What type of content are you interested in',
  sub_title: `Choose as many as you'd like`,
  answers: contentTypes.map((i) => ({ text: i })),
  model: 'content_type',
}

const questionThoughts1 = {
  name: names.THOUGHTS_1,
  type: types.RADIO,
  title: 'Thoughts',
  sub_title: 'Do you relate to the statement below?',
  comment: `Do you experience repetitive and
    persistent thoughts that are upsetting and unwanted?`,
  answers: [
    {
      text: 'YES',
      img: require('@/assets/icons/like.svg'),
    },
    {
      text: 'NO',
      img: require('@/assets/icons/dislike.svg'),
    },
  ],
  model: 'thoughts_1',
}
const questionThoughts2 = {
  name: names.THOUGHTS_2,
  type: types.RADIO,
  title: 'Thoughts',
  sub_title: 'Do you relate to the statement below?',
  comment: `Do you experience intense anxiety or worry and find it difficult to control?`,
  answers: [
    {
      text: 'YES',
      img: require('@/assets/icons/like.svg'),
    },
    {
      text: 'NO',
      img: require('@/assets/icons/dislike.svg'),
    },
  ],
  model: 'thoughts_2',
}

const questionLoader1 = {
  name: names.LOADER_1,
  type: types.CUSTOM,
  component: 'ProgressBar',
}

const questionLoader2 = {
  name: names.LOADER_2,
  type: types.CUSTOM,
  component: 'ProgressBar',
}

const questionLoader3 = {
  name: names.LOADER_3,
  type: types.CUSTOM,
  component: 'ProgressBar',
}

const questionOneLast = {
  name: names.ONE_LAST,
  type: types.RADIO,
  title: 'One Last Thing',
  sub_title: `How much time are you willing to spend <br/>
    every day to solve the problem?`,
  answers: times.map((i) => ({ text: i })),
  model: 'times',
}

const questionEmail = {
  name: names.EMAIL,
  type: types.CUSTOM,
  component: 'Email',
}

const questionTestimonial = {
  name: names.TESTIMONIAL,
  type: types.TESTIMONIAL,
  component: 'Testimonial',
  index: 0,
}

const questionTestimonial2 = {
  name: names.TESTIMONIAL,
  type: types.TESTIMONIAL,
  component: 'Testimonial',
  index: 1,
}

const questionMentalInfo = {
  name: names.MENTAL_INFO,
  type: types.CUSTOM,
  component: 'MentalInfo',
}

const questionMentalRecap = {
  name: names.MENTAL_RECAP,
  type: types.CUSTOM,
  component: 'MentalRecap',
}

export const goalQuestions = {
  [goal.INCREASE_CONFIDENCE]: questionConfidence,
  [goal.INCREASE_ENERGY]: questionHigherEnergy,
  [goal.INCREASE_HAPPINESS]: questionHigherHappiness,
  [goal.INCREASE_MOTIVATION]: questionHigherMotivation,
  [goal.REDUCE_STRESS]: questionStress,
  [goal.SLEEP_BETTER]: questionHigherSleep,
}

export const quiz1 = [
  questionGender,
  questionGoal,
  questionAge,
  questionTypicalDay,
  questionOurMethod,
  questionTension,
  questionEnergy,
  questionSleep,
  questionMotivation,
  questionEmotions,
  questionChallenges,
  questionLoader1,
  questionOneLast,
  questionLoader2,
]

export const quiz2 = [
  questionGender,
  questionGoal,
  questionAge,
  questionTypicalDay,
  questionOurMethod,
  questionTension,
  questionEnergy,
  questionSleep,
  questionMotivation,
  questionThoughts1,
  questionThoughts2,
  questionGuilt,
  questionConcentration,
  questionMood,
  questionFear,
  questionEmotions,
  questionChallenges,
  questionLoader1,
  questionOneLast,
  questionLoader2,
  // questionEmail,
]

export const quiz3 = [
  questionGoal,
  questionSleep,
  questionFeelingSad,
  questionEnergyRate,
  questionAppetite,
  questionGuilt,
  questionConcentration,
  questionMood,
  questionEffects,
  questionMotivation,
  questionThoughts2,
  questionFear,
  questionEmotions,
  questionChallenges,
  questionLoader1,
  questionOneLast,
  questionLoader2,
  // questionEmail,
]

export const quiz4 = [
  questionGoal,
  questionThoughtsNervous,
  questionThoughtsNegativity,
  questionWorry,
  questionTension,
  questionFearAfraid,
  questionMotivation,
  questionEmotions,
  questionChallenges,
  questionLoader1,
  questionOneLast,
  questionLoader2,
  // questionEmail,
]

export const quiz5 = [
  questionGoal,
  questionContentType,
  questionTestimonial,
  questionGender,
  questionTypicalDay,
  questionChallenges,
  questionMentalInfo,
  questionLoader1,
  questionFeel,
  questionAge,
  questionOneLast,
  questionLoader2,
  questionMentalRecap,
  // questionEmail,
]

export const quiz7 = [
  questionGoal2,
  questionAge,
  questionGender,
  questionTestimonial,
  questionEmotions2,
  questionWorkplace,
  questionChallenges2,
  questionTestimonial2,
  questionLoader3,
]
