<template>
  <div v-if="isQuizDataLoading || isSingleQuizLoading" class="quiz-loading">
    <a-spin />
  </div>
  <div class="quiz-content" :key="question.id" v-else>
    <quiz-yes-no-field
      v-if="question.block_type === 'Yes/No Type'"
      :fieldTitle="question.title"
      :fieldDescription="question.description"
      :question="question"
      :value="quizForm.custom_quiz[question.id]"
      @fieldChange="
        (value) => {
          changeHandler({ custom_quiz: { [question.id]: value } })
          setQuizForm()
        }
      "
    />

    <quiz-multi-single-field
      v-if="
        question.block_type === 'Attribute Question' &&
        question.quiz_screen_name === 'goal'
      "
      isMulti
      :fieldTitle="question.title"
      :fieldDescription="question.description"
      :question="question.answers"
      :value="quizForm[question.quiz_screen_name]"
      @next="setQuizForm"
      @fieldChange="
        (value) => changeHandler({ [question.quiz_screen_name]: value })
      "
    />

    <quiz-age-field
      v-if="
        question.block_type === 'Attribute Question' &&
        question.quiz_screen_name === 'age'
      "
      :fieldTitle="question.title"
      :fieldDescription="question.description"
      :question="question"
      :value="quizForm[question.quiz_screen_name]"
      @fieldChange="
        (value) => {
          changeHandler({ [question.quiz_screen_name]: value })
          setQuizForm()
        }
      "
    />

    <quiz-testimonials-field
      v-if="question.block_type === 'Testimonials'"
      :fieldTitle="question.testimonial_percentage + `%`"
      :fieldDescription="question.title"
      :question="question"
      @next="setQuizForm"
    />

    <quiz-info-field
      v-if="question.block_type === 'Info Screen'"
      :fieldTitle="question.title"
      :fieldDescription="question.description"
      :question="question"
      @next="setQuizForm"
    />

    <quiz-multi-single-field
      v-if="question.block_type === 'Single Choice'"
      :fieldTitle="question.title"
      :fieldDescription="question.description"
      :question="question.options"
      :value="quizForm.custom_quiz[question.id]"
      @next="setQuizForm"
      @fieldChange="
        (value) => changeHandler({ custom_quiz: { [question.id]: value } })
      "
    />

    <quiz-multi-single-field
      v-if="question.block_type === 'Multiple Choice'"
      isMulti
      :fieldTitle="question.title"
      :fieldDescription="question.description"
      :question="question.options"
      :value="quizForm.custom_quiz[question.id]"
      @next="setQuizForm"
      @fieldChange="
        (value) => changeHandler({ custom_quiz: { [question.id]: value } })
      "
    />

    <quiz-typical-day
      v-if="
        question.block_type === 'Attribute Question' &&
        question.quiz_screen_name === 'typical_day'
      "
      :fieldTitle="question.title"
      :fieldDescription="question.description"
      :question="question"
      :value="quizForm[question.quiz_screen_name]"
      @fieldChange="
        (value) => {
          changeHandler({ [question.quiz_screen_name]: value })
          setQuizForm()
        }
      "
    />

    <quiz-gender-field
      v-if="
        question.block_type === 'Attribute Question' &&
        question.quiz_screen_name === 'gender'
      "
      :fieldTitle="question.title"
      :fieldDescription="question.description"
      :question="question"
      :value="quizForm[question.quiz_screen_name]"
      @fieldChange="
        (value) => {
          changeHandler({ [question.quiz_screen_name]: value })
          setQuizForm()
        }
      "
    />

    <div
      class="quiz__content space-between"
      v-if="question.quiz_screen_name === 'loading'"
    >
      <div class="quiz__title">analyzing</div>
      <ProgressBar full @done="showResult()" />
      <LoaderList />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  genderIcon,
  typicalDayImage,
  answersQuestions,
  ageLabel,
} from './quiz-data'
import SwiperCore, { Pagination } from 'swiper'
import QuizYesNoField from './QuizYesNoField.vue'
import QuizMultiSingleField from './QuizMultiSingleField.vue'
import QuizAgeField from './QuizAgeField.vue'
import QuizTestimonialsField from './QuizTestimonialsField.vue'
import QuizInfoField from './QuizInfoField.vue'
import QuizTypicalDay from './QuizTypicalDay.vue'
import QuizGenderField from './QuizGenderField.vue'
import { setQuiz } from '@/services/quiz.service'
import ProgressBar from '../bar/ProgressBar.vue'
import LoaderList from './LoaderList.vue'

SwiperCore.use([Pagination])

export default {
  name: 'QuizCustomContent',
  components: {
    QuizYesNoField,
    QuizMultiSingleField,
    QuizAgeField,
    QuizTestimonialsField,
    QuizInfoField,
    QuizTypicalDay,
    QuizGenderField,
    ProgressBar,
    LoaderList,
  },
  data() {
    return {
      questions: [],
    }
  },

  computed: {
    ...mapState('quiz', {
      fields: 'fields',
      current: 'progress',
      quizForm: 'quizForm',
      totalTest: 'totalTest',
      quiz: 'quiz',
      quizData: 'quizData',
      isQuizDataLoading: 'isQuizDataLoading',
      isSingleQuizLoading: 'isSingleQuizLoading',
    }),

    customizedFields() {
      return this.questions.map((question) => {
        if (question.block_type === 'Attribute Question') {
          const field = this.fields[question.quiz_screen_name]
          const fieldTitle = question.quiz_screen_name
          return {
            ...question,
            answers: field.map((j) => {
              const v = field.find((k) => k.text === j.text) || {}
              if (fieldTitle === 'typical_day') {
                return {
                  ...j,
                  value: v.value,
                  img: typicalDayImage[v.text],
                }
              }
              if (fieldTitle === 'gender') {
                return {
                  ...j,
                  value: v.value,
                  img: genderIcon[v.text],
                }
              }
              if (fieldTitle === 'age') {
                return {
                  ...j,
                  value: v.value,
                  label: ageLabel[v.text],
                }
              }
              return {
                ...j,
                value: v.value,
              }
            }),
            title: answersQuestions[question.quiz_screen_name].title,
            description: answersQuestions[question.quiz_screen_name].subtitle,
          }
        }

        if (question.info_image) {
          this.preloadImage(question.info_image)
        }

        return question
      })
    },
    question() {
      return this.customizedFields[this.current - 1]
    },

    ...mapState(['user', 'token']),
    filteredGoalItems() {
      return this.fields.goal.filter((i) =>
        this.quizForm.goal.includes(i.value)
      )
    },
  },
  methods: {
    ...mapMutations('quiz', [
      'SET_PROGRESS',
      'SET_QUIZ_FORM',
      'SET_TOTAL_TEST',
    ]),
    preloadImage(url) {
      const link = document.createElement('link')
      link.rel = 'preload'
      link.as = 'image'
      link.href = url
      document.head.appendChild(link)
    },
    showResult() {
      this.SET_PROGRESS(this.current + 1)
      if (this.current >= this.quizData.quiz_block.length) {
        this.SET_QUIZ_FORM({ ...this.quizForm, isQuizCompleted: true })
      }
      if (this.current >= this.quizData.quiz_block.length && this.token) {
        return this.$router.push({
          name: 'QuizDynamicResults',
        })
      }
    },
    setQuizForm(value) {
      setQuiz({
        statistic_id: this.quiz.statistic_id,
        action: 'complete_block',
        block_id: this.question.id,
      })
      this.SET_PROGRESS(this.current + 1)
    },
    changeHandler(value) {
      const newForm = { ...this.quizForm, ...value }
      if (value.custom_quiz) {
        newForm.custom_quiz = {
          ...this.quizForm.custom_quiz,
          ...value.custom_quiz,
        }
      }
      this.SET_QUIZ_FORM(newForm)
    },
    isDisableBtn(element) {
      if (Array.isArray(element)) {
        return !element.length
      }
      return !element
    },
  },

  watch: {
    fields: {
      immediate: true,
      handler() {
        if (this.quizData.goal_type === 'Single Goal') {
          const goalId = this.fields.goal.find(
            (item) => item.text === this.quizData.single_goal
          )?.value
          this.SET_QUIZ_FORM({ ...this.quizForm, goal: goalId })
        }
      },
    },
    quizData: {
      immediate: true,
      handler() {
        this.questions = this.quizData.quiz_block
        this.questions.push({
          id: 'loadingAnimation',
          quiz_screen_name: 'loading',
        })
        this.SET_TOTAL_TEST(this.questions.length)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.no-margin {
  margin: 0%;
}

.uppercase {
  text-transform: uppercase;
}

.mb-20 {
  margin-bottom: 20px;
}
.progress {
  width: 327px;
  margin: 0 auto;
}

.error-message {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
  display: block;
  margin-top: 4px;
  padding: 0;
}

.quiz-loading {
  display: grid;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 112px);
}

.quiz-btn__container {
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: #4e8b72;

    .quiz-btn {
      margin: 15px 0;
    }
  }
}
</style>
